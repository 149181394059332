import { FlexboxGrid as View, Content, Col, Stack, Panel, Grid, Row } from 'rsuite';
import Cover from '../../../components/bm_cover.jsx';
import '../../../assets/bmhome.scss'
import food from '../../../assets/panel-img/panel-img-food.jpg';
import events from '../../../assets/panel-img/panel-img-events.jpg';
import services from '../../../assets/panel-img/panel-img-services.jpg';
import shop from '../../../assets/panel-img/panel-img-shop.jpg';
import partner from '../../../assets/panel-img/panel-img-becomepartner.jpg';
import hostEvent from '../../../assets/panel-img/panel-img-hostevent.jpg';
import { Link } from 'react-router-dom';

export default function BarkadaMarketHome(){

  return (
    
    <Content>
      {/* <div className='mp-bg'></div> */}
      <div className="floating-img-bg"></div>
      <Cover alt="cover"/>
      <View justify='center' style={{ marginBottom: 80 }}>
        <View.Item as={Col} colspan={24} xl={14} lg={17} md={22} sm={24} className='max-1120'>
          <div className='panel-tile-heading'>
            <h3>Your everyday, right away</h3>
            <p>Order food and grocery delivery online from hundreds of restaurants and shops nearby.</p>
          </div>
          <div className='panel-tile-container'>
            <Grid fluid>
              <Row gutter={20}>
                <Col lg={12} md={12} sm={24} xs={24} className='m-b-20'>
                  <Link to='/restaurants'>
                    <Panel bordered bodyFill>
                      <Stack justifyContent='flex-start'>
                        <Stack.Item className='img-placeholder'>
                          <img src={food} alt="Food" width={120} height={130}  />
                        </Stack.Item>
                        <div className='panel-tile-desc'>
                          <h5>Food</h5>
                          <small>Find deals, free delivery, and more from our curated restaurant partners.</small>
                          <span className='link-placeholder'>Explore</span>
                        </div>
                      </Stack>
                    </Panel>
                  </Link>
                </Col>
                <Col lg={12} md={12} sm={24} xs={24} className='m-b-20'>
                  <Link to='/events'>
                    <Panel bordered bodyFill>
                      <Stack justifyContent='flex-start'>
                        <Stack.Item className='img-placeholder'>
                          <img src={events} alt="Events" width={120} height={130}  />
                        </Stack.Item>
                        <div className='panel-tile-desc'>
                          <h5>Events</h5>
                          <small>Enhance your event and ensure its success with our robust management features.</small>
                          <span className='link-placeholder'>Explore</span>
                        </div>
                      </Stack>
                    </Panel>
                  </Link>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col lg={12} md={12} sm={24} xs={24} className='m-b-20'>
                  <Panel bordered bodyFill>
                    <Stack justifyContent='flex-start'>
                      <Stack.Item className='img-placeholder'>
                        <img src={services} alt="Services - Coming Soon" width={120} height={130}  />
                      </Stack.Item>
                      <div className='panel-tile-desc'>
                        <h5>Services - Coming Soon</h5>
                        <small>Maximize service potential and enhance customer satisfaction with our management features.</small>
                      </div>
                    </Stack>
                  </Panel>
                </Col>
                <Col lg={12} md={12} sm={24} xs={24} className='m-b-20'>
                  <Panel bordered bodyFill>
                    <Stack justifyContent='flex-start'>
                      <Stack.Item className='img-placeholder'>
                        <img src={shop} alt="Shop - Coming Soon" width={120} height={130}  />
                      </Stack.Item>
                      <div className='panel-tile-desc'>
                        <h5>Shop - Coming Soon</h5>
                        <small>Boost your sales and streamline operations with our comprehensive shop management features.</small>
                      </div>
                    </Stack>
                  </Panel>
                </Col>
              </Row>
            </Grid>
          </div>
        </View.Item>
      </View>
      <View justify='center' style={{ paddingBottom: 50 }}>
        <View.Item as={Col} colspan={24} xl={14} lg={17} md={22} sm={24} className='max-1120'>
          <div className='panel-tile-heading'>
            <h3>Join Us</h3>
            <p>Be a part of the Barkada Market story</p>
          </div>
          <div className='panel-tile-container'>
            <Grid fluid>
              <Row gutter={20}>
                <Col lg={12} md={12} sm={24} xs={24} className='m-b-20'>
                  <Link to={import.meta.env.VITE_APP_URL}>
                    <Panel bordered bodyFill>
                      <Stack justifyContent='flex-start'>
                        <Stack.Item className='img-placeholder'>
                          <img src={partner} alt="logo" width={120} height={130}  />
                        </Stack.Item>
                        <div className='panel-tile-desc'>
                          <h5>Become our partner</h5>
                          <small>Reach more customer and achieve growth with Barkada Market</small>
                          <span className='link-placeholder'>Learn More</span>
                        </div>
                      </Stack>
                    </Panel>
                  </Link>
                </Col>
                <Col lg={12} md={12} sm={24} xs={24} className='m-b-20'>
                  <Panel bordered bodyFill>
                    <Stack justifyContent='flex-start'>
                      <Stack.Item className='img-placeholder'>
                        <img src={hostEvent} alt="logo" width={120} height={130}  />
                      </Stack.Item>
                      <div className='panel-tile-desc'>
                        <h5>Host your events</h5>
                        <small>Coming Soon</small>
                      </div>
                    </Stack>
                  </Panel>
                </Col>
              </Row>
            </Grid>
          </div>
        </View.Item>
      </View>
    </Content>
  );
}